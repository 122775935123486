import { ClockIcon, LightBulbIcon, AcademicCapIcon, LibraryIcon, ScaleIcon, ShieldCheckIcon, SparklesIcon, XIcon, CurrencyDollarIcon, ArrowRightIcon, LocationMarkerIcon, PhoneIcon, MailIcon, SearchIcon, CheckIcon } from '@heroicons/react/outline';
import { StarIcon } from '@heroicons/react/solid';
import React, { useState } from 'react';
import AccordionItem from './Accordion/AccordionItem';
import Calculator from './Calculator/Calculator';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import Form from './Form';
import Navbar from './Navbar'

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default function LandingPage(props) {

    const [showPolicy, setShowPolicy] = useState(true);


    const accordionArray = [
        {
            id: 1,
            question: "Can the IRS forgive my back taxes?",
            answer: "Yes, an offer in compromise is a settlement between you (the debtor) and the IRS to settle your tax debt for less than the full amount originally owed."
        },
        {
            id: 2,
            question: "Can I clear my tax debt?",
            answer: "Yes, payment and settlement plans are two potential options that allow debtors to resolve their tax debt with the IRS. However each case is unique with its own set of unique challenges."
        },
        {
            id: 3,
            question: "Can I negotiate my back taxes with the IRS?",
            answer: "Yes, you can negotiate with the IRS on back taxes! Our licensed tax professionals investigate your debt situation and construct a negotiation plan on your behalf."
        },
        {
            id: 4,
            question: "How do I know if I qualify for tax debt forgiveness?",
            answer: "Several factors are taken into account for tax debt forgiveness including family size and current financial situation. Our tax debt professionals will make sure the IRS takes these factors into account so you get the optimal forgiveness."
        },
    ]

    const aboutArray = [
        {
            id: 1,
            icon: LightBulbIcon,
            title: "Fix Tax Debt",
            content: "We’ll find the best program and take advantage of every opportunity to garner the most aggressive settlement options available to you."
        },
        {
            id: 2,
            icon: AcademicCapIcon,
            title: "Stop Wage Garnishments",
            content: "We can help end wage garnishment quickly, freeing up YOUR spending money and hard earned cash."
        },
        {
            id: 3,
            icon: LibraryIcon,
            title: "File Your Back Tax Returns",
            content: "We file back taxes for you making taxes simpler than ever."
        },
        {
            id: 4,
            icon: ScaleIcon,
            title: "IRS Tax Audits",
            content: "Make tax audits straight forward and resolve them quickly with our team by your side."
        },
    ]


    const testimonialArray = [
        {
            id: 1,
            name: "Bonnie Smith",
            title: "Highly professional and friendly service...",
            text: "I can't recommend Ovation Tax Group highly enough. They helped and guided me through every step of the way through a recent tax matter allowing me to have complete peace of mind through the whole process. Engaging a an expert like this lawyers was defenitely the best thing I did. They are very knowledgeable in the areas of Tax law and provides a highly professional and friendly service.",
            image: "/testimonial_1.webp",
            date: "5 months ago"
        },
        {
            id: 2,
            name: "Will Dent",
            title: "Clear path forward...",
            text: "After numerous attempts to resolve the matter on our own, we went to the Ovation Tax group and had a great meeting with Ms. Kim. She provided us with a clear path forward and took away all the anxiety from dealing with the issue ourselves.",
            image: "/testimonial_2.webp",
            date: "5 months ago"
        },
        {
            id: 3,
            name: "Ashley Bank",
            title: "Knowledgeable, professional, and worked extremely...",
            text: "This team is phenomena! I experienced an unexpected wage garnishment and contacted the team Ovation Tax Group. They are very knowledgeable, professional, and worked extremely fast as well. They resolved my tax issue within 48 hours! ",
            image: "/testimonial_3.webp",
            date: "5 months ago"
        },
    ]


    const testimonialArray1 = [
        {
            id: 1,
            name: "Angel Reyes",
            title: "Best possible result...",
            text: "I want to thank you for everything you did to resolve my mother’s estate tax matter with IRS. You produced the best possible result. The beneficiaries are most grateful and happy to recommend you to others.",
            date: "a month ago"
        },
        {
            id: 2,
            name: "Nick Burton",
            title: "Recommend to my family...",
            text: "I would definitely give Ovation Tax Group five stars I went there to file my taxes and got back more than I could even expect I will be going there every year from now on yes I would recommend my family.",
            date: "5 months ago"
        },
        {
            id: 3,
            name: "Antonio Montana",
            title: "Five stars...",
            text: "I've been coming to Ovation Tax Group for a very long time and every time I was satisfied I would recommend to my family and friends they can help you from doing your taxes to help with the IRS five stars all the way.",
            date: "5 months ago"
        },
    ]



    const OptionsArray = [
        {
            id: 1,
            title: "1",
            content: "Consultation",
            icon: PhoneIcon
        },
        {
            id: 2,
            title: "2",
            content: "Investigation",
            icon: SearchIcon
        },
        {
            id: 3,
            title: "3",
            content: "Negotiation",
            icon: ScaleIcon
        },
        {
            id: 4,
            title: "4",
            content: "Tax debt free",
            icon: CurrencyDollarIcon
        },
    ]


    const servicesArray = [
        {
            id: 1,
            title: "Offer In Compromise",
            content: "Agreement to settle tax liabilities for less than originally owed.",
            icon: CurrencyDollarIcon
        },
        {
            id: 2,
            title: "Installment Agreement",
            content: "Installment payments over a period of time given to the IRS.",
            icon: ClockIcon
        },
        {
            id: 3,
            title: "Fresh Start Forgiveness",
            content: "Pay debts without expensive penalties paid to the IRS.",
            icon: SparklesIcon
        },
        {
            id: 4,
            title: "Penalty Abatement",
            content: "Request abatement of certain penalties for a single tax period.",
            icon: ShieldCheckIcon
        },

    ]

    return (
        <div className='relative'>
            {/* CTA start */}
            <div className='banner'>
                <Navbar did={props.did} />
                <div className='lg:hidden block text-center'>
                    <div className='bg-gray-300 p-1'>
                        <p className="text-gray-700 uppercase text-xs font-semibold">Free Quote Today:</p>
                    </div>
                    <a href={`tel:${props.did}`} id="CTAPhoneButtonMobile">
                        <div className='w-full bg-red-600 p-2 text-white font-bold tracking-wide text-xl'>{props.did}</div>
                    </a>
                </div>
                <div className='relative flex md:items-center justify-center'>
                    <div className='p-5 md:p-10 md:pb-5 lg:py-14'>
                        <div className='max-w-2xl mx-auto lg:my-auto'>
                            <div className='text-center mb-10'>
                                <h1 className="mt-5 md:mt-0 text-3xl tracking-tight font-extrabold text-white md:text-5xl lg:text-5xl">
                                    Remove Your Tax Debt
                                    in Just
                                    <span className="wendy ml-3 tracking-wide text-yellow-500">24 Hours</span>
                                </h1>
                                <p className="sm:text-lg md:text-lg text-white font-semibold sm:mt-5 max-w-xl mx-auto mt-5 md:mt-5">
                                    Have a tax debt problem? At Ovation Tax Group, our qualified tax debt attorneys and professionals are here to help resolve IRS tax debt.
                                </p>

                                <div className='text-center'>
                                    <a id="CTAPhoneButton" href={`tel:${props.did}`}>
                                        <div className="mt-5 mx-auto w-fit rounded-sm drop-shadow-md hover:drop-shadow-2xl p-5 md:px-10 md:py-5 bg-dodger-200 hover:bg-dodger-600 text-white font-extrabold text-xl md:text-2xl uppercase tracking-widest">
                                            Get Debt Free Today
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>

                        {/* <FadeIn className='bg-blue-800 bg-opacity-50 md:col-span-6 h-full w-full px-10 rounded-t-lg'>
                            <Form did={props.did} />
                        </FadeIn> */}
                    </div>
                </div>
            </div>
            {/* CTA end */}


            {/* banner start */}
            {/* <div>
                <div className="hidden md:block bg-gradient-to-r from-jeans-200 p-2 md:px-5 md:py-5 text-sm md:text-xl text-blue-700 font-bold">
                    <p className='flex flex-col lg:flex-row items-center justify-center uppercase font-bold tracking-wider'>Not sure where to start?
                        <a href="#quiz" id="takeQuizButton" className='mt-2 md:mt-5 lg:mt-0 ml-2 text-dodger-900 bg-dodger-200 bg-opacity-50 hover:bg-dodger-200 hover:text-gray-800 px-5 py-2 rounded-full font-black uppercase tracking-widest flex items-center justify-center'>Take free quiz <ArrowRightIcon className='h-5 w-5' /></a>
                    </p>
                </div>
            </div> */}

            {/* banner end */}



            {/* testimonials */}
            <div className='bg-blue-100'>
                {/* <FadeIn className='max-w-6xl mx-auto grid md:grid-cols-3 gap-4'> */}

                <Swiper
                    // install Swiper modules
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    spaceBetween={50}
                    slidesPerView={1}
                    navigation
                    pagination={{ clickable: true }}
                >
                    {testimonialArray1.map((item) => (
                        <SwiperSlide key={item.id} className="py-5 px-2 md:p-5 bg-gray-100">
                            <div className='max-w-4xl mx-auto items-center justify-center relative'>
                                <div className='md:grid grid-cols-4 my-auto mx-5 cursor-default p-6'>
                                    <div className='col-span-1'>
                                        <div className='flex items-center justify-start'>
                                            <img src="google_logo.png" alt="Google" className='h-8 w-8' />
                                            <StarIcon className='h-6 w-6 text-yellow-500' />
                                            <StarIcon className='h-6 w-6 text-yellow-500' />
                                            <StarIcon className='h-6 w-6 text-yellow-500' />
                                            <StarIcon className='h-6 w-6 text-yellow-500' />
                                            <StarIcon className='h-6 w-6 text-yellow-500' />
                                        </div>
                                        <p className='font-semibold text-lg mb-0 text-gray-800'>{item.name}</p>
                                        <p className='font-normal text-sm mb-2 md:mb-0 text-gray-600'>{item.date}</p>
                                    </div>
                                    <p className='my-auto col-span-3 text-md text-left text-base md:text-lg text-gray-500 leading-5'>"{item.text}"</p>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>




                {/* <div className='bg-white bg-opacity-75 p-5 rounded-sm'>
                        <p className='font-bold text-base md:text-lg'>Angel R.</p>
                        <p className='text-sm my-2'>"I want to thank you for everything you did to <b>resolve my mother’s estate
                            tax</b> matter with IRS.
                            You produced the <b>best possible result</b>. The beneficiaries are most grateful
                            and happy to recommend you to others."</p>
                        <div className='mt-2 flex items-center justify-start'>
                            <StarIcon className='h-4 w-4 text-yellow-300' />
                            <StarIcon className='h-4 w-4 text-yellow-300' />
                            <StarIcon className='h-4 w-4 text-yellow-300' />
                            <StarIcon className='h-4 w-4 text-yellow-300' />
                            <StarIcon className='h-4 w-4 text-yellow-300' />
                        </div>
                    </div>
                    <div className='bg-white bg-opacity-75 p-5 rounded-sm'>
                        <p className='font-bold text-base md:text-lg'>Nick B.</p>
                        <p className='text-sm my-2'>"I would definitely give Ovation Tax Group <b>five stars</b> I went there
                            to file my taxes and got back more than I could even
                            expect I will be going there every year from now on
                            yes I would <b>recommend my family</b>."</p>
                        <div className='mt-2 flex items-center justify-start'>
                            <StarIcon className='h-4 w-4 text-yellow-300' />
                            <StarIcon className='h-4 w-4 text-yellow-300' />
                            <StarIcon className='h-4 w-4 text-yellow-300' />
                            <StarIcon className='h-4 w-4 text-yellow-300' />
                            <StarIcon className='h-4 w-4 text-yellow-300' />
                        </div>
                    </div>
                    <div className='bg-white bg-opacity-75 p-5 rounded-sm'>
                        <p className='font-bold text-base md:text-lg'>Antonio M.</p>
                        <p className='text-sm my-2'>"I've been coming to Ovation Tax Group for a very long time
                            and every time I was satisfied I would <b> recommend to my family
                                and friends</b> they can help you from doing your
                            taxes to help with the IRS <b>five stars</b> all the way."</p>
                        <div className='mt-2 flex items-center justify-start'>
                            <StarIcon className='h-4 w-4 text-yellow-300' />
                            <StarIcon className='h-4 w-4 text-yellow-300' />
                            <StarIcon className='h-4 w-4 text-yellow-300' />
                            <StarIcon className='h-4 w-4 text-yellow-300' />
                            <StarIcon className='h-4 w-4 text-yellow-300' />
                        </div>
                    </div> */}
                {/* </FadeIn> */}
            </div>

            {/* end testimonials */}



            {/* services start*/}
            <div className='max-w-6xl mx-auto px-5 mt-20' id="services">
                <h1 className='wendy tracking-wide text-center text-gray-900 font-black text-3xl md:text-4xl px-1 md:px-0 mb-2 md:mb-5'>Tax Services</h1>
                <p className='text-gray-500 text-lg max-w-2xl mx-auto text-center mb-10'>Our team of tax professionals are up-to-date with tax practices, procedures, and relevant codes. We pride ourselves on our ability to fix any tax problem you throw our way.</p>
                <div className='bg-gradient-to-r from-dodger-800 to-blue-500 rounded-lg grid md:grid-cols-4 text-2xl max-w-6xl mx-auto'>
                    {servicesArray.map((item) => (
                        <a key={item.id} id="ServicesContactButton" href="#contact" className="flex flex-col items-center border-2 border-white hover:border-none justify-center group hover:-mt-2 hover:bg-yellow-500 p-2 hover:scale-110 text-center hover:drop-shadow-2xl hover:rounded-lg h-60 text-white hover:text-blue-700">
                            <div className='bg-blue-700 group-hover:bg-yellow-300 border-2 rounded-lg border-yellow-700 group-hover:border-blue-700 p-4 w-fit mx-auto'>
                                <item.icon className="text-yellow-500 group-hover:text-blue-500 h-8 w-8" />
                            </div>
                            <p className='mt-5 font-bold text-lg'>{item.title}</p>
                            <p className='text-base'>{item.content}</p>
                            <p className='mt-2 group-hover:flex hidden items-center justify-center uppercase text-sm font-bold'>Learn more <ArrowRightIcon className='ml-2 h-4 w-4' /></p>
                        </a>
                    ))}
                </div>
            </div>
            {/* services end*/}



            {/* About us  start */}
            <div id="about" className='grid gap-10 md:grid-cols-2 justify-center max-w-6xl mt-20 mb-20 md:my-20 px-5 mx-auto'>
                <div className='order-last md:order-first'>
                    <h1 className='wendy trakcing-wide md:text-left text-center text-gray-900 font-black text-3xl md:text-4xl px-1 md:px-0 mb-2 md:mb-5'>Start a Tax Debt Resolution Plan Today</h1>
                    <p className='text-gray-500 text-lg max-w-2xl mx-auto mb-5'>Our back tax specialists have the ability to handle any complicated tax challenge.</p>
                    <p className='text-gray-500 text-lg max-w-2xl mx-auto mb-5'>
                        Licensed and up-to-date on all things tax, our team of experienced and efficient professionals is waiting to help you on your journey
                        to back tax forgiveness.
                    </p>
                    <p className='text-gray-500 text-lg max-w-2xl mx-auto mb-5'>
                        <span className='italic'>Give us a call at
                            <a id="callNowPhoneButton1" href={`tel:${props.did}`} className='mx-2 text-gray-700 font-semibold hover:text-gray-900 underline uppercase'>
                                {props.did}
                            </a>
                            to get your free tax debt consultation.
                        </span>
                    </p>


                </div>
                <div className='my-auto'>
                    <img src="/team2.webp" className="object-contain h-full w-auto m-auto" alt="Tax Settlement Services" style={{ maxHeight: "400px" }} />
                </div>
            </div>
            {/* About us  end */}



            {/* Help start */}
            <div className='bg-gray-100 mt-20'>
                <div className='py-20 px-5 md:px-0'>
                    <h1 className='text-center text-gray-900 font-black text-3xl md:text-4xl mb-2 md:mb-5'>How does tax debt resolution work?</h1>
                    <div className='hidden lg:block mx-auto h-2 mb-10 w-full border-gray-400 border-dotted border-t-4 mt-20'></div>
                    <div className='grid md:grid-cols-2 lg:grid-cols-4 gap-2 text-2xl'>
                        {OptionsArray.map((item) => (
                            <div className="relative mx-auto max-w-6xl" key={item.id}>
                                <div className='hidden lg:block mx-auto -mt-20 bg-blue-700 border-2 rounded-lg border-gray-400 p-4 w-fit'>
                                    <item.icon className="text-yellow-500  h-8 w-8" />
                                </div>
                                <div className="mt-14 flex items-center justify-center text-center">
                                    <div className='font-extrabold'>
                                        <div className='block lg:hidden mx-auto mb-3 bg-blue-700 border-2 rounded-lg border-gray-400 p-4 w-fit'>
                                            <item.icon className="text-yellow-500  h-8 w-8" />
                                        </div>
                                        <span className='wendy uppercase z-50 text-3xl md:text-4xl tracking-widest text-blue-700'>STEP {item.title}</span>
                                        <p className='lato2 text-gray-800 text-xl'>{item.content}</p>
                                    </div>

                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {/* Help end */}




            {/* help start */}
            <div id="help" className='grid gap-10 md:grid-cols-2 justify-center max-w-6xl px-5 my-20 mx-auto'>
                <div className='my-auto'>
                    <img src="/team.webp" className="object-contain h-full w-auto m-auto" alt="Tax Settlement Services" style={{ maxHeight: "400px" }} />
                </div>
                <div>
                    <h1 className='wendy tracking-wide md:text-left text-center text-gray-900 font-black text-3xl md:text-4xl px-1 md:px-0 mb-2 md:mb-5'>Get Help with Tax Debt</h1>
                    <p className='text-gray-500 text-lg lg:max-w-2xl lg:mx-auto text-center mb-5 md:text-left'>If you need any assistance, our team of qualified professionals can help with a variety of tax debt problems.</p>
                    <div className='my-10 max-w-sm'>
                        <div className='mb-2 md:mb-2 text-base sm:text-xl md:text-xl font-bold text-gray-700 flex flex-nowrap items-center justify-center md:justify-start'><CheckIcon className='text-green-600 mr-2 h-5 w-5 md:h-10 md:w-10' />Quick & easy</div>
                        <div className='mb-2 md:mb-2 text-base sm:text-xl md:text-xl font-bold text-gray-700 flex flex-nowrap items-center justify-center md:justify-start'><CheckIcon className='text-green-600 mr-2 h-5 w-5 md:h-10 md:w-10' />Good & fast service</div>
                        <div className='text-base sm:text-xl md:text-xl font-bold text-gray-700 flex flex-nowrap items-center justify-center md:justify-start'><CheckIcon className='text-green-600 mr-2 h-5 w-5 md:h-10 md:w-10' />Very professional & knowledgeable</div>
                    </div>
                    <div>
                        <p className='mt-4 font-bold text-gray-800 text-lg capitalize text-center md:text-left italic'>Start a resolution plan today!</p>
                        <p className='text-gray-500 text-lg max-w-2xl mx-auto mb-5'>
                            <span className='italic'>Call our team of professionals at
                                <a id="callNowPhoneButton2" href={`tel:${props.did}`} className='mx-2 text-gray-700 font-semibold hover:text-gray-900 underline uppercase'>
                                    {props.did}
                                </a>
                                to start your free tax debt consultation.
                            </span>
                        </p>
                    </div>
                </div>
            </div>
            {/* help end */}

            {/* Quiz start */}
            <div className='bg-gray-100'>
                <div id="quiz" className='px-5 py-10 max-w-7xl w-full mx-auto text-semibold text-gray-900 md:text-xl text-md xl:rounded-lg '>
                    <p className='text-center mt-0 lato2'>Tax Forgiveness</p>
                    <h1 className='wendy tracking-wide text-center text-gray-900 font-black text-3xl md:text-4xl px-1 md:px-0 mb-2 md:mb-5'>Quiz</h1>
                    <Calculator did={props.did} />
                </div>
            </div>
            {/* Quiz end */}


            {/* Services 2 */}
            <div className='justify-center max-w-6xl px-5 my-20 mx-auto'>
                <h1 className='wendy tracking-wide text-center text-gray-900 font-black text-3xl md:text-4xl px-1 md:px-0 mb-2 md:mb-5'>Resolve Tax Debt Issues</h1>
                <p className='text-gray-500 text-lg lg:max-w-2xl lg:mx-auto text-center mb-5 md:text-left'>Let the team at Ovation tax Group help you resolve your tax issues quickly and with no headache.</p>
                <div className='max-w-6xl px-5 my-20 mx-auto grid md:grid-cols-4 m-auto gap-10'>

                    {aboutArray.map((item) => (
                        <a id="howCanWeHelpYouServicesButton" className="cursor-pointer group" href="#contact" key={item.id}>
                            <div className="relative bg-navy-200 bg-opacity-20 hover:bg-opacity-10 my-auto rounded-lg shadow hover:shadow-2xl bg-zinc h-full group cursor-default lg:p-6 p-4">
                                <div className='absolute rounded-lg -mt-5 ml-2 top-0 left-0 bg-blue-500 group-hover:bg-yellow-500 text-white p-4'>
                                    <item.icon className='h-10 w-10' />
                                </div>

                                <p className='mt-10 text-gray-800 font-black text-md lg:text-lg'>
                                    <span className='group-hover:text-blue-400'>{item.title}</span></p>
                                <p className='text-sm text-gray-700 mt-3'>{item.content}</p>
                            </div>
                        </a>
                    ))}
                </div>
            </div>
            {/* Services 2 end */}

            {/* testimonials start */}
            <div id="testimonials" className='pb-20 md:py-20'>
                <div>
                    <h1 className='wendy tracking-wide text-center text-gray-900 font-black text-3xl md:text-4xl px-1 md:px-0 mb-2 md:mb-5'>Google Verified Customer Reviews</h1>
                    <p className='text-gray-500 text-lg max-w-2xl mx-auto text-center'>We take the time to analyze your specific tax-related problem and formulate a plan that's unique to your current financial situation.</p>
                </div>
                <Swiper
                    // install Swiper modules
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    spaceBetween={50}
                    slidesPerView={1}
                    navigation
                    pagination={{ clickable: true }}
                >
                    {testimonialArray.map((item) => (
                        <SwiperSlide key={item.id} className="pb-8 md:pb-10">
                            <div className='max-w-4xl mx-auto items-center justify-center relative'>
                                {/* <img src={item.image} className='-mr-10 col-span-1 object-fit mx-auto' alt={item.name} /> */}

                                <div className=' bg-white my-auto rounded-lg mx-5 shadow cursor-default py-6 px-2 md:p-6'>
                                    <div className='md:grid grid-cols-4 my-auto mx-5 cursor-default md:p-6'>
                                        <div className='my-auto col-span-1'>
                                            <div className='flex items-center justify-start'>
                                                <img src="google_logo.png" alt="Google" className='h-8 w-8' />
                                                <StarIcon className='h-6 w-6 text-yellow-500' />
                                                <StarIcon className='h-6 w-6 text-yellow-500' />
                                                <StarIcon className='h-6 w-6 text-yellow-500' />
                                                <StarIcon className='h-6 w-6 text-yellow-500' />
                                                <StarIcon className='h-6 w-6 text-yellow-500' />
                                            </div>
                                            <p className='font-semibold text-lg mb-0 text-gray-800'>{item.name}</p>
                                            <p className='font-normal text-sm mb-2 md:mb-0 text-gray-600'>{item.date}</p>
                                        </div>
                                        <p className='my-auto col-span-3 text-md text-left text-base md:text-lg text-gray-500 leading-5'>"{item.text}"</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            {/* testimonials end */}


            {/* FAQ start */}
            <div className='py-20 px-5 mx-auto bg-gray-100' id="faq">
                <h1 className='wendy tracking-wide text-center text-gray-900 font-black text-3xl md:text-4xl px-1 md:px-0 mb-2 md:mb-5'>Frequently Asked Questions</h1>
                {accordionArray.map((item) => (
                    <div key={item.id} className="max-w-2xl mx-auto">
                        <AccordionItem item={item} />
                    </div>
                ))}
                <div>
                    <p className='text-gray-500 text-lg text-center max-w-2xl mx-auto mb-5'>
                        <span className='italic'>Still have a question? Call us at
                            <a id="callNowPhoneButton3" href={`tel:${props.did}`} className='mx-2 text-gray-700 font-semibold hover:text-gray-900 underline uppercase'>
                                {props.did}
                            </a>
                            and we can help answer any of you tax related concerns.
                        </span>
                    </p>
                </div>
            </div>
            {/* Faq end */}


            <div className='text-center md:text-left text-xl bg-gradient-to-bl from-dodger-800  to-blue-500' id="contact">
                <div className='grid lg:grid-cols-12'>
                    <div className='my-auto lg:col-span-6 py-20 px-5 max-w-lg mx-auto'>
                        <h1 className='wendy tracking-wide md:text-left text-center text-white font-black text-3xl md:text-4xl mb-2 md:mb-5'>
                            <span className="block xl:inline">Ready to <span className="text-yellow-500 xl:inline">solve</span> your taxes?</span>{' '}
                        </h1>
                        <p className="mt-3 text-base text-white sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0 mb-10">
                            Get in contact with our team of qualified tax debt attorneys and professionals today.
                        </p>
                        <span className='flex flex-nowrap items-center justify-start text-left text-white mb-5'>
                            <div className='bg-blue-700 border-2 rounded-lg border-yellow-700 p-4 w-fit mr-2'>
                                <LocationMarkerIcon className="text-yellow-500  h-6 w-6" />
                            </div>
                            19839 Nordhoff St, Northridge, CA 91324
                        </span>
                        <span className='flex flex-nowrap items-center justify-start text-left text-white mb-5'>
                            <div className='bg-blue-700 border-2 rounded-lg border-yellow-700 p-4 w-fit mr-2'>
                                <PhoneIcon className="text-yellow-500  h-6 w-6" />
                            </div>
                            <a id="contactUsPhoneButton" className="hover:underline" href={`tel:${props.did}`}>{props.did}</a>
                        </span>
                        <span className='flex flex-nowrap items-center justify-start text-left text-white'>
                            <div className='bg-blue-700 border-2 rounded-lg border-yellow-700 p-4 w-fit mr-2'>
                                <MailIcon className="text-yellow-500  h-6 w-6" />
                            </div>
                            <a id="contactUsEmailButton" className="hover:underline" href="mailto:info@ovationtaxgroup.com">info@ovationtaxgroup.com</a>
                        </span>
                    </div>
                    <div className='lg:col-span-6 w-full h-full rounded-lg'>
                        <iframe title="location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3298.627848543082!2d-118.5659827!3d34.232519599999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c29b77d792920b%3A0x58048e7790aeede7!2s19839%20Nordhoff%20St%2C%20Northridge%2C%20CA%2091324!5e0!3m2!1sen!2sus!4v1722288806334!5m2!1sen!2sus" className='w-full h-full' style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>

                </div>
                <div className='bg-blue-800 bg-opacity-50 h-full px-5'>
                    <Form did={props.did} />
                </div>
            </div>



            {/* Footer start */}
            <div className='py-10 text-sm bg-blue-800 text-white text-center'>
                <img src="/logo.png" alt="Ovation Tax Group" className='h-10 mb-4 mx-auto' />
                <p>©2023 Ovation Tax Group. All rights reserved. </p>
                <a id="privacyPolicyButton" className="underline" href="/privacy-policy">Privacy Policy</a>
            </div>
            {/* Footer end */}


            {/* Privacy policy banner start */}
            {showPolicy &&
                <div className='fixed bottom-0 w-screen z-50'>
                    <div className='drop-shadow-2xl bg-gray-200 text-gray-700 text-sm p-2'>
                        <div className='flex items-center justify-between'>
                            <p>By using this site, you agree to our <a className="ml-1 hover:underline text-blue-300 hover:text-blue-900" href="/privacy-policy"> Privacy Policy</a>.</p>
                            <XIcon onClick={() => setShowPolicy(false)} className="text-gray-800 hover:text-gray-500 h-6 w-6 mr-5" />
                        </div>
                    </div>
                </div>
            }
            {/* Privacy policy banner end */}
        </div>
    );
}
