import React, { useState } from 'react';
import Input from 'react-phone-number-input/input'
import { XCircleIcon } from '@heroicons/react/outline';
import styled, { keyframes } from 'styled-components'
import { fadeIn } from 'react-animations';
import { useNavigate } from 'react-router-dom';

const FadeIn = styled.div`animation: 2s ${keyframes`${fadeIn}`}`

export default function Form(props) {

    const navigate = useNavigate();
    const [showSuccess, setShowSuccess] = useState(false)
    const [disabledButton, setDisabledButton] = useState(false)


    const [dataArray, setDataArray] = useState({
        firstName: "",
        lastName: "",
        email: "",
        cellPhone: "",
    })

    const [phone, setPhone] = useState(dataArray.cellPhone);

    const updateFieldValue = (name, value) => {
        let newDataArray = { ...dataArray };
        newDataArray[name] = value
        setDataArray(newDataArray)
    }


    const errorObj = Object.keys(dataArray).reduce((prev, curr) => (prev[curr] = false, prev), {})

    //create an object of key value pair set to false each with a key === names of all basic form field keys
    const [errors, setErrors] = React.useState({ ...errorObj })

    const validEmail = new RegExp(
        '^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$'
    );

    const validFormFields = () => {
        let updateErrorObj = { ...errors };
        Object.entries(dataArray).some(([key, value]) => {
            if (key === "email") {
                if (!validEmail.test(value) || !value || value.length === 0) {
                    updateErrorObj[key] = true
                } else {
                    updateErrorObj[key] = false
                }
            } else if (key === 'cellPhone') {
                if (!phone || phone.replace("+", '').length !== 11) {
                    updateErrorObj[key] = true
                } else {
                    updateErrorObj[key] = false
                }

            } else if (!value) {
                updateErrorObj[key] = true;
                // console.log("error", key, value)
            } else {
                updateErrorObj[key] = false
            }
        })
        setErrors(updateErrorObj)
        return (Object.values(updateErrorObj).includes(true)) ? false : true
    }


    const date = new Date();

    const pst = date.toLocaleString('en-US', {
        timeZone: 'America/Los_Angeles',
    });

    const endpoint = 'https://eamtfgwhtot2mmt4wspcoseeve0fiqqf.lambda-url.us-west-1.on.aws/' // Add this later

    const email = "info@ovationtaxgroup.com"
    const subject = "Ovation Tax Website Form Submission"
    const message = `
        Name: ${dataArray.firstName} ${dataArray.lastName}\n
        Email: ${dataArray.email}\n
        Phone: ${dataArray.cellPhone}\n
        Time Stamp: ${pst} PST
    `


    const handleNextStep = () => {
        if (validFormFields()) {
            //disable submit button
            setDisabledButton(true)
            const data = { email, message, subject }
            const fetchPromise = fetch(endpoint, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                body: JSON.stringify(data)
            });
            fetchPromise
                .then((response) => {
                    // on success, clear any errors and set submitted state to true
                    response.json()
                    //turn button back on
                    setDisabledButton(false)
                    // setShowSuccess(true)
                    navigate("/confirmation/success")
                })
            // .then(data => {
            //     console.log(data); // handle response, catch errors
            // })
        }
    }



    if (showSuccess) {
        return (
            <FadeIn>
                <div id="contactFormSuccess" className='px-2 md:px-5 py-20 rounded-lg mx-auto md:max-w-xl text-center text-white'>
                    <p className='mb-10 font-bold text-xl md:text-3xl lato1 uppercase tracking-widest'>Thank you for reaching out.</p>
                    <p className='mb-10 font-bold text-md md:text-lg lato2'>
                        A member of our team will be in contact shortly to discuss you tax debt situation in greater detail.
                    </p>
                    <p className='text-md text-yellow-500 wendy text-lg tracking-wide'>Looking to start immediately?</p>
                    <p> <a id="contactFormSuccessPhoneButton" className="font-bold text-xl " href={`tel:${props.did}`}>{props.did}</a></p>
                </div>
            </FadeIn>

        )
    } else {
        return (
            <div className='px-2 md:px-5 py-10 rounded-lg mx-auto md:max-w-xl'>
                <p className='mb-10 font-bold text-xl md:text-2xl lato1 text-white text-center uppercase tracking-widest'>Complimentary Tax Consultation</p>
                <div className='grid md:grid-cols-12 gap-4'>
                    {Object.entries(dataArray).map(([key, value]) =>
                    (key === 'cellPhone' ?
                        <div key={key} className="col-span-12 mb-2">
                            <Input
                                defaultCountry="US"
                                value={phone}
                                onChange={setPhone}
                                placeholder='Phone Number'
                                className="mt-1 block w-full rounded-sm py-2 px-3 border border-gray-300 bg-white text-gray-800 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            />
                            {errors[key] &&
                                <p className="mt-2 mb-0 text-xs text-red-500 flex">
                                    <XCircleIcon className="h-4 w-4 mr-1 text-red-500" /> Please enter a valid U.S. phone number.
                                </p>
                            }
                        </div>
                        :
                        (key === 'email' ?
                            <div key={key} className="col-span-12 mb-2">
                                <input
                                    type="text"
                                    name={key}
                                    maxLength="50"
                                    id={`${value}-${key}`}
                                    defaultValue={`${value}`}
                                    placeholder="Email Address"
                                    onChange={(event) => updateFieldValue(event.target.name, event.target.value)}
                                    className="mt-1 block w-full rounded-sm py-2 px-3 border border-gray-300 bg-white text-gray-800 focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm"
                                />
                                {errors[key] &&
                                    <p className="mt-2 mb-0 text-xs text-red-500 flex">
                                        <XCircleIcon className="h-4 w-4 mr-1 text-red-500" /> Please enter a valid {key.replace(/_/g, ' ')}.
                                    </p>
                                }
                            </div>
                            :
                            <div key={key} className="md:col-span-6 col-span-12 mb-2">
                                <input
                                    type="text"
                                    name={key}
                                    maxLength="50"
                                    id={`${value}-${key}`}
                                    defaultValue={`${value}`}
                                    placeholder={key === "firstName" ? "First Name" : "Last Name"}
                                    onChange={(event) => updateFieldValue(event.target.name, event.target.value)}
                                    className="mt-1 block w-full py-2 px-3 rounded-sm border border-gray-300 bg-white text-gray-800 focus:outline-none focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm"
                                />
                                {errors[key] &&
                                    <p className="mt-2 mb-0 text-xs text-red-500 flex">
                                        <XCircleIcon className="h-4 w-4 mr-1 text-red-500" /> Please enter a valid value.
                                    </p>
                                }
                            </div>)
                    )
                    )}
                </div>
                <p className='text-justify text-white text-xs mt-2 mb-3'>I acknowledge that by clicking “FREE CONSULTATION” I agree to be contacted by Ovation Tax Group and its affiliates via prerecorded and/or robo-dialed telemarketing calls and/or SMS/MMS text messages via telephone, mobile device and/or email. By doing so I waive any registration to any state, federal or corporate Do Not Call registry. I understand consent is not required to purchase goods or services. I agree to receive approximately 10 messages every month and understand message & data rates may apply.</p>
                <button
                    id="ContactFormSubmitButton"
                    disabled={disabledButton}
                    onClick={(event) => handleNextStep(event)}
                    className={`
                        mx-auto w-full rounded-sm drop-shadow-md 
                        hover:drop-shadow-2xl p-5 md:px-10 md:py-5 
                        font-extrabold text-xl md:text-2xl uppercase tracking-widest
                        ${disabledButton ? "text-gray-400 bg-gray-200" : "text-white bg-dodger-200 hover:bg-dodger-600"}
                        `}>
                    Free Consultation
                </button>
            </div>
        )
    }

}
