import { Outlet } from "react-router-dom";
import Navbar from './Navbar'
import { useLocation } from 'react-router-dom'

const Layout = (props) => {

  const location = useLocation();
  return (
    <>
      {location.pathname !== "/"  && <Navbar did={props.did}/>}
      {location.pathname === "/confirmation/success" && null}
      <Outlet />
    </>
  )
};

export default Layout;