import React, { useState } from 'react';
import { XCircleIcon } from '@heroicons/react/outline';
import Input from 'react-phone-number-input/input'
import { useNavigate } from 'react-router-dom';

export default function Step4({ prevStep, nextStep, updateFieldValue, ...FormFields }) {

  const navigate = useNavigate();

  const [phone, setPhone] = useState(FormFields.cellPhone);

  const validEmail = new RegExp(
    '^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$'
  );

  const [disabledButton, setDisabledButton] = useState(false)

  const errorObj = {
    firstName: false,
    lastName: false,
    email: false,
    cellPhone: false
  }

  const [errors, setErrors] = useState({ ...errorObj })

  const validFormFields = () => {
    let updateErrorObj = { ...errors };
    Object.entries(FormFields).some(([key, value]) => {
      if (key === "email") {
        if (!validEmail.test(value) || !value || value.length === 0) {
          updateErrorObj[key] = true
        } else {
          updateErrorObj[key] = false
        }
      } else if (key === 'cellPhone') {
        if (!phone || phone.replace("+", '').length !== 11) {
          updateErrorObj[key] = true
        } else {
          updateErrorObj[key] = false
        }

      } else if (!value) {
        updateErrorObj[key] = true;
        // console.log("error", key, value)
      } else {
        updateErrorObj[key] = false
      }
      return null
    })
    setErrors(updateErrorObj)
    return (Object.values(updateErrorObj).includes(true)) ? false : true
  }


  const date = new Date();

  const pst = date.toLocaleString('en-US', {
    timeZone: 'America/Los_Angeles',
  });

  const endpoint = 'https://eamtfgwhtot2mmt4wspcoseeve0fiqqf.lambda-url.us-west-1.on.aws/'

  const email = "info@ovationtaxgroup.com"
  const subject = "Ovation Tax Website Form Submission"
  const message = `
      Name: ${FormFields.firstName} ${FormFields.lastName}\n
      Email: ${FormFields.email}\n
      Phone: ${FormFields.cellPhone}\n
      Time Stamp: ${pst} PST
  `


  const handleNextStep = () => {
    if (validFormFields()) {
      //disable submit button
      setDisabledButton(true)
      const data = { email, message, subject }
      const fetchPromise = fetch(endpoint, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        body: JSON.stringify(data)
      });
      fetchPromise
        .then(() => {
          // on success, clear any errors and set submitted state to true
          //turn button back on
          setDisabledButton(false)
          // nextStep(4)
          navigate("/confirmation/success")
        })
      // .then(data => {
      //   console.log(data); // handle response, catch errors
      // })
    }
  }


  return (
    <div id="quizFormStep4" className='bg-white rounded-lg p-10 max-w-sm mx-auto'>
      <div className='mx-auto md:grid md:grid-cols-12 md:gap-4'>
        {Object.entries(FormFields).map(([key, value]) =>
          <div key={key} className={`${key === "firstName" || key === "lastName" ? 'md:col-span-6 mb-6 md:mb-0' : 'col-span-12 mb-6 md:mb-0'}`}>
            {key === 'firstName' || key === 'lastName' ?
              <div>
                <label htmlFor="state" className="block text-lg font-medium mb-2 text-gray-700">
                  {key === 'firstName' ? "First Name" : "Last Name"}
                </label>
                <input
                  type="text"
                  name={key}
                  maxLength="100"
                  id={`${value}-${key}`}
                  defaultValue={`${value}`}
                  onChange={(event) => updateFieldValue(event.target.name, event.target.value)}
                  className={`px-4 ${errors[key] ? 'border-red-600 bg-red-50' : 'border-gray-200'} border font-semibold py-2 text-xl rounded-md w-full text-black bg-white`}
                />
                {errors[key] &&
                  <p className="mt-2 mb-0 text-xs text-red-500 flex">
                    <XCircleIcon className="h-4 w-4 mr-1" /> Please enter a value.
                  </p>
                }
              </div>
              :
              (key === "cellPhone" ?
                <div>
                  <label htmlFor={`${value}-${key}`} className="block text-lg font-medium mb-2 text-gray-700">
                    Phone Number
                  </label>
                  <Input
                    defaultCountry="US"
                    country="US"
                    value={phone}
                    onChange={setPhone}
                    className={`px-4 ${errors[key] ? 'border-red-600 bg-red-50' : 'border-gray-200'} border font-semibold py-2 text-xl rounded-md w-full text-black bg-white`}
                  />
                  {errors[key] &&
                    <p className="mt-2 mb-0 text-xs text-red-500 flex">
                      <XCircleIcon className="h-4 w-4 mr-1" /> Please enter a valid U.S. phone number.
                    </p>
                  }
                </div>
                :

                <div>
                  <label htmlFor={`${value}-${key}`} className="block text-lg font-medium capitalize mb-2 text-gray-700">
                    {key.replace(/_/g, ' ')}
                  </label>
                  <input
                    type="text"
                    name={key}
                    maxLength="100"
                    id={`${value}-${key}`}
                    defaultValue={`${value}`}
                    onChange={(event) => updateFieldValue(event.target.name, event.target.value)}
                    className={`px-4 ${errors[key] ? 'border-red-600 bg-red-50' : 'border-gray-200'} border font-semibold py-2 text-xl rounded-md w-full text-black bg-white`}
                  />
                  {errors[key] &&
                    <p className="mt-2 mb-0 text-xs text-red-500 flex">
                      <XCircleIcon className="h-4 w-4 mr-1" /> Please enter a valid value.
                    </p>
                  }
                </div>)
            }
          </div>
        )}
      </div>
      <p className='text-justify text-gray-500 text-xs mt-10'>I acknowledge that by clicking “SUBMIT” I agree to be contacted by Ovation Tax Group and its affiliates via prerecorded and/or robo-dialed telemarketing calls and/or SMS/MMS text messages via telephone, mobile device and/or email. By doing so I waive any registration to any state, federal or corporate Do Not Call registry. I understand consent is not required to purchase goods or services. I agree to receive approximately 10 messages every month and understand message & data rates may apply.</p>
      <div className='flex items-center justify-center'>
        <button
          id="calculatorFormSubmitButton"
          onClick={() => handleNextStep()}
          disabled={disabledButton}
          className={`
          w-full 
          max-w-sm 
          mt-10 
          ${disabledButton ? "text-gray-400 bg-gray-200" : "bg-dodger-200 hover:bg-dodger-600 text-white"}
          text-xl 
          font-bold 
          uppercase 
          tracking-widest 
          p-4 
          rounded-md
          `}>
          Submit
        </button>
      </div>
    </div>
  );
}