import React, { useState } from 'react'
import { XCircleIcon, ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/outline';

export default function Step3({ prevStep, nextStep, updateFieldValue, ...FormFields }) {

  const validZipCode = new RegExp('^[0-9]{5}(?:-[0-9]{4})?$')

  const errorObj = {
    zipCode: false
  }

  const [errors, setErrors] = useState({ ...errorObj })

  const validFormFields = () => {
    let updateErrorObj = { ...errors };
    Object.entries(FormFields).some(([key, value]) => {
      if (key === "state") {
        updateErrorObj[key] = false
      } else if (!validZipCode.test(value) || !value) {
        updateErrorObj[key] = true;
        console.log("error", key, value)
      } else {
        updateErrorObj[key] = false
      }
      return null
    })
    setErrors(updateErrorObj)
    return (Object.values(updateErrorObj).includes(true)) ? false : true
  }

  const handleValue = () => {
    if (validFormFields()) {
      // if no errors...
      try {
        nextStep(3)
      } catch (error) {
        console.error('error', error);
      }
    }
  }

  return (
    <div id="quizFormStep3" className='md:flex items-center justify-center'>
      <div>
        <button
          id="quizFormStep3PrevButton"
          onClick={() => prevStep(3)}
          className="hidden md:block w-fit bg-gray-300 hover:bg-yellow-500 text-black text-md font-bold uppercase tracking-widest p-4 rounded-md">
          <ChevronLeftIcon className='h-5 w-5' />
        </button>
      </div>
      <div className="bg-white rounded-lg p-10 max-w-sm md:w-96 mx-auto md:mx-2">
        {Object.entries(FormFields).map(([key, value]) =>
          <div key={key}>
            {key === 'state' ?
              <div>
                <label htmlFor="state" className="block text-lg font-medium capitalize mb-2 text-gray-700">
                  {key.replace(/_/g, ' ')}
                </label>
                <select
                  id="state"
                  name="state"
                  defaultValue="CA"
                  onChange={(event) => updateFieldValue(event.target.name, event.target.value)}
                  className={`px-4 ${errors[key] ? 'border-red-600 bg-red-50' : 'border-gray-200'} border font-semibold py-2 text-xl rounded-md w-full text-black bg-white`}
                >
                  <option value="AL">Alabama - AL</option>
                  <option value="AK">Alaska - AK</option>
                  <option value="AZ">Arizona - AZ</option>
                  <option value="AR">Arkansas - AR</option>
                  <option value="CA">California - CA</option>
                  <option value="CO">Colorado - CO</option>
                  <option value="CT">Connecticut - CT</option>
                  <option value="DE">Delaware - DE</option>
                  <option value="FL">Florida - FL</option>
                  <option value="GA">Georgia - GA</option>
                  <option value="HI">Hawaii - HI</option>
                  <option value="ID">Idaho - ID</option>
                  <option value="IL">Illinois - IL</option>
                  <option value="IN">Indiana - IN</option>
                  <option value="IA">Iowa - IA</option>
                  <option value="KS">Kansas - KS</option>
                  <option value="KY">Kentucky - KY</option>
                  <option value="LA">Louisiana - LA</option>
                  <option value="ME">Maine - ME</option>
                  <option value="MD">Maryland - MD</option>
                  <option value="MA">Massachusetts - MA</option>
                  <option value="MI">Michigan - MI</option>
                  <option value="MN">Minnesota - MN</option>
                  <option value="MS">Mississippi - MS</option>
                  <option value="MO">Missouri - MO</option>
                  <option value="MT">Montana - MT</option>
                  <option value="NE">Nebraska - NE</option>
                  <option value="NV">Nevada - NV</option>
                  <option value="NH">New Hampshire - NH</option>
                  <option value="NJ">New Jersey - NJ</option>
                  <option value="NM">New Mexico - NM</option>
                  <option value="NY">New York - NY</option>
                  <option value="NC">North Carolina - NC</option>
                  <option value="ND">North Dakota - ND</option>
                  <option value="OH">Ohio - OH</option>
                  <option value="OK">Oklahoma - OK</option>
                  <option value="OR">Oregon - OR</option>
                  <option value="PA">Pennsylvania - PA</option>
                  <option value="RI">Rhode Island - RI</option>
                  <option value="SC">South Carolina - SC</option>
                  <option value="SD">South Dakota - SD</option>
                  <option value="TN">Tennessee - TN</option>
                  <option value="TX">Texas - TX</option>
                  <option value="UT">Utah - UT</option>
                  <option value="VT">Vermont - VT</option>
                  <option value="VA">Virginia - VA</option>
                  <option value="WA">Washington - WA</option>
                  <option value="DC">Washington D.C. - DC</option>
                  <option value="WV">West Virginia - WV</option>
                  <option value="WI">Wisconsin - WI</option>
                  <option value="WY">Wyoming - WY</option>
                </select>
              </div>
              :
              <div className="mb-6">
                <label htmlFor={`${value}-${key}`} className="block text-lg font-medium mb-2 text-gray-700 capitalize">
                  ZIP code
                </label>
                <input
                  type="text"
                  name={key}
                  maxLength="5"
                  id={`${value}-${key}`}
                  defaultValue={`${value}`}
                  onChange={(event) => updateFieldValue(event.target.name, event.target.value)}
                  className={`px-4 ${errors[key] ? 'border-red-600 bg-red-50' : 'border-gray-200'} border font-semibold py-2 text-xl rounded-md w-full text-black bg-white`}
                />
                {errors[key] &&
                  <p className="mt-2 mb-0 text-xs text-red-500 flex">
                    <XCircleIcon className="h-4 w-4 mr-1" /> Please enter a valid ZIP code (U.S. postal code).
                  </p>
                }
              </div>
            }
          </div>
        )}
      </div>

      <div>
        <button
          id="quizFormStep3NextButton"
          onClick={() => handleValue()}
          className="hidden md:block w-fit bg-gray-300 hover:bg-yellow-500 text-black text-md font-bold uppercase tracking-widest p-4 rounded-md">
          <ChevronRightIcon className='h-5 w-5' />
        </button>
      </div>
      <div className='md:hidden flex items-center justify-center mt-5'>
        <button
          id="quizFormStep3PrevButtonMobile"
          onClick={() => prevStep(3)}
          className="w-fit bg-yellow-500 hover:bg-gray-300 mx-2 text-black text-md font-bold uppercase tracking-widest p-4 rounded-md">
          Previous
        </button>
        <button
          id="quizFormStep3NextButtonMobile"
          onClick={() => handleValue()}
          className="w-fit bg-yellow-500 hover:bg-gray-300 mx-2 text-black text-md font-bold uppercase tracking-widest p-4 rounded-md">
          Next
        </button>
      </div>
    </div>
  );
}
