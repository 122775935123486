import React, { useState } from 'react'
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Contact from './Contact';


export default function StepForm(props) {
  const [currentStep, setCurrentStep] = useState(1)

  const prevStep = (step) => {
    setCurrentStep(step - 1)
  }

  const nextStep = (step) => {
    setCurrentStep(step + 1)
  }

  const steps = () => {
    switch (currentStep) {
      case 1:
        return (
          <Step1
            nextStep={nextStep}
            updateFieldValue={props.updateFieldValue}
            oweDebt={props.dataArray.oweDebt}
          />
        )
      case 2:
        return (
          <Step2
            prevStep={prevStep}
            nextStep={nextStep}
            updateFieldValue={props.updateFieldValue}
            totalDebt={props.dataArray.totalDebt}
          />
        )
      case 3:
        return (
          <Step3
            prevStep={prevStep}
            nextStep={nextStep}
            updateFieldValue={props.updateFieldValue}
            zipCode={props.dataArray.zipCode}
            state={props.dataArray.state}
          />
        )
      case 4:
        return (
          <Step4
            prevStep={prevStep}
            nextStep={nextStep}
            updateFieldValue={props.updateFieldValue}
            firstName={props.dataArray.firstName}
            lastName={props.dataArray.lastName}
            email={props.dataArray.email}
            cellPhone={props.dataArray.cellPhone}
          />
        )
      case 5:
        return (
          <Contact />
        )

      default:
      // do nothing
    }
  }



  return (
    <div>
      <div className='relative max-w-3xl mx-auto'>
          <p className="text-gray-600 ml-2 mb-2 text-center">Step {currentStep} of 5</p>
          <div className="w-full bg-white h-5 rounded-full mb-8 max-w-sm mx-auto shadow">
            <div className="bg-dodger-200 h-5 rounded-full"
              style={
                currentStep === 1 ? { width: '20%' }
                  : currentStep === 2 ? { width: '40%' }
                    : currentStep === 3 ? { width: '60%' }
                      : currentStep === 4 ? { width: '80%' }
                        : { width: '100%' }}></div>
          </div>

          <div>
            {steps()}</div>
      </div>
    </div>
  )
}
