import React from 'react';

export default function Contact() {
  return (
    <div id="quizFormSubmissionLoading" className="bg-white rounded-lg px-5 py-20 max-w-sm mx-auto text-center text-gray-700">
      <p className='md:text-xl text-lg  mb-10 font-bold'>Submitting information...</p>
      <p className='text-md'>Please please wait</p>
    </div>
  );
}
