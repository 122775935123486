/* This example requires Tailwind CSS v2.0+ */
import React from 'react'
import { Disclosure } from '@headlessui/react'
import { MenuIcon, XIcon, PhoneIcon } from '@heroicons/react/outline'

const navigation = [
  { name: 'Helpful FAQ', href: '/#faq', current: false },
  { name: 'Quiz', href: '/#quiz', current: true },
  { name: 'Contact', href: '/#contact', current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Navbar(props) {
  return (
    <Disclosure as="nav" className="banner relative md:py-5 z-50">
      {({ open }) => (
        <>
          <div className="z-50 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-navy-800 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center lg:items-stretch lg:justify-start">
                <a id="homeNavButton" href="/">
                  <div className="wendy flex-shrink-0 text-md md:text-xl flex items-center text-white uppercase tracking-widest">
                    <img
                      className="h-10 sm:h-12 w-auto mr-2"
                      src="/logo.png"
                      alt="Ovation"
                    /> Ovation Tax Group
                  </div>
                </a>
                <div className="hidden lg:block my-auto sm:ml-6">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <a
                        id={`${item.name}NavButton`}
                        key={item.name}
                        href={item.href}
                        className={classNames(`text-white hover:bg-gray-100 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium`
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
              <div>
              <p className='hidden lg:block mb-0 font-bold text-white text-sm capitalize text-right'>Free Tax Debt Consultation</p>
                <a id="navPhoneButton" href={`tel:${props.did}`}>
                  <div className="hidden lg:block mx-auto md:mx-0 w-fit rounded-sm hover:shadow-sm drop-shadow-2xl text-white hover:text-yellow-400 font-bold text-3xl">
                    {props.did}
                  </div>
                </a>
              
              </div>
            </div>
          </div>

          <Disclosure.Panel className="lg:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    'text-white hover:bg-gray-100 hover:text-gray-900 block px-3 py-2 rounded-md text-sm font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>

              ))}
              <a id="navPhoneButtonMobile" href={`tel:${props.did}`}
                className="flex items-center p-1 rounded-full text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
              >
                <span className="sr-only">Call now</span>
                <PhoneIcon className="mr-2 h-4 w-4" aria-hidden="true" /> {props.did}
              </a>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
