import React, { useState } from 'react'
import { ChevronLeftIcon, ChevronRightIcon, XCircleIcon } from '@heroicons/react/outline'

export default function Step1({ prevStep, nextStep, updateFieldValue, oweDebt }) {

    const [debtYes, setDebtYes] = useState(null)

    const [error, setError] = useState(false)

    const handleNextStep = () => {
        if (debtYes === null) {
            setError(true)
        } else {
            setError(false)
            nextStep(1)
        }

    }

    const handleYes = (value) => {
        updateFieldValue('oweDebt', value)
        setDebtYes(value)
        setError(false)
        nextStep(1)
    }



    return (
        <div id="quizFormStep1" className='md:flex items-center justify-center'>
            <div>
                <button
                    id="quizFormStep1PrevButton"
                    className="md:block hidden w-fit bg-gray-300  text-black text-md font-bold uppercase tracking-widest p-4 rounded-md">
                    <ChevronLeftIcon className='h-5 w-5' />
                </button>
            </div>
            <div className="bg-white shadow-lg rounded-lg p-10 max-w-sm mx-auto md:mx-2">
                <div>
                    <label htmlFor="tax debt" className="block text-md font-medium mb-2 text-gray-700">
                        Do you owe tax debt to the IRS?
                    </label>
                    <div className="mt-4 space-y-4">
                        <div className="group flex items-center">
                            <input
                                id="owe-debt-yes"
                                name="owe-debt-yes"
                                type="radio"
                                onChange={() => handleYes("yes")}
                                checked={debtYes === "yes"}
                                className="focus:ring-indigo-500 h-8 w-8 text-indigo-600 border-gray-300"
                            />
                            <label htmlFor="owe-debt-yes" className="group-hover:text-gray-500 ml-3 block text-lg font-medium text-black">
                                Yes
                            </label>
                        </div>
                        <div className="group flex items-center">
                            <input
                                id="owe-debt-no"
                                name="owe-debt-no"
                                type="radio"
                                onChange={() => handleYes("no")}
                                checked={debtYes === "no"}
                                className="focus:ring-indigo-500 h-8 w-8 text-indigo-600 border-gray-300"
                            />
                            <label htmlFor="owe-debt-no" className="group-hover:text-gray-500 ml-3 block text-lg font-medium text-black">
                                No
                            </label>
                        </div>
                    </div>
                    {error &&
                        <p className="mt-2 mb-0 text-xs text-red-500 flex">
                            <XCircleIcon className="h-4 w-4 mr-1" /> Please select a value.
                        </p>
                    }
                </div>
            </div>
            <div>
                <button
                    id="quizFormStep1NextButton"
                    onClick={() => handleNextStep()}
                    className="md:block hidden w-fit bg-gray-300 hover:bg-yellow-500 text-black text-md font-bold uppercase tracking-widest p-4 rounded-md">
                    <ChevronRightIcon className='h-5 w-5' />
                </button>
            </div>
            <div className='md:hidden flex items-center justify-center mt-5'>
                <button
                    id="quizFormStep1PrevButtonMobile"
                    className="w-fit bg-gray-300 mx-2 text-black text-md font-bold uppercase tracking-widest p-4 rounded-md">
                    Previous
                </button>
                <button
                    id="quizFormStep1NextButtonMobile"
                    onClick={() => handleNextStep()}
                    className="w-fit bg-yellow-500 mx-2 hover:bg-gray-300 text-black text-md font-bold uppercase tracking-widest p-4 rounded-md">
                    Next
                </button>
            </div>
        </div>
    );
}