import React, { useState } from 'react'
import StepForm from './StepForm';



export default function Calculator(props) {
    const [dataArray, setDataArray] = useState({
        oweDebt:"",
        totalDebt:'10000',
        state:"CA",
        zipCode:"",
        firstName:"",
        lastName:"",
        email:"",
        cellPhone:""
    })

    const updateFieldValue = (name, value) => {
        let newDataArray = {...dataArray};
        newDataArray[name] = value
        setDataArray(newDataArray)
    }

    return (
        <div>
            <StepForm dataArray={dataArray} updateFieldValue={updateFieldValue} did={props.did}/>
        </div>              
    );
}
