import React, { useRef, useEffect } from 'react';
import { CheckCircleIcon } from '@heroicons/react/outline';
import Navbar from './Navbar';

export default function Success(props) {

    const successRef = useRef(null);

    useEffect(() => {
        successRef.current?.scrollIntoView({ behavior: 'smooth', block: "start" });
    })


    return (
        <div className='banner' ref={successRef}>
            <Navbar did={props.did} />
            <div className='relative flex justify-center h-screen'>
                <div className='pt-2 md:pt-10 p-2 md:px-0'>
                    <div className='bg-white bg-opacity-75 p-5 rounded-sm'>
                        <div className='bg-white p-5 md:p-10 mx-auto max-w-xl'>
                            <div className='text-center md:pt-16 lg:pt-0'>
                                <div className='flex items-center justify-center'><CheckCircleIcon className='h-24 w-24 text-green-600' /></div>
                                <h2 className="wendy tracking-wide font-bold text-3xl md:text-4xl text-green-700 mt-10 max-w-xl mx-auto">
                                    Thank you!
                                </h2>
                                <p className="text-lg text-gray-800 font-semibold sm:mt-5 max-w-lg mx-auto mt-5 md:mt-5">
                                    A member of our team will be in contact shortly to discuss your tax debt situation in greater detail.
                                </p>

                                <div className='mt-10'>
                                    <p className="mt-10 text-center font-bold text-2xl text-blue-700 uppercase sm:mt-5 max-w-lg mx-auto">
                                        Call now
                                    </p>
                                    <div className='flex justify-center'>
                                        <a id="FormSuccessPhoneButton" href={`tel:${props.did}`}>
                                            <div className="mt-3 rounded-sm drop-shadow-md hover:drop-shadow-2xl p-5 bg-dodger-200 hover:bg-dodger-600 text-white font-extrabold text-xl md:text-2xl uppercase tracking-widest">
                                                {props.did}
                                            </div>
                                        </a>
                                    </div>
                                    <p className='text-gray-500 text-base max-w-xs mx-auto mt-3 mb-5'>
                                        <span className='italic'>
                                            to start your free tax debt consultation
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Footer start */}
            <div className='py-10 text-sm bg-blue-800 text-white text-center'>
                <img src="/logo.png" alt="Ovation Tax Group" className='h-10 mb-4 mx-auto' />
                <p>©2023 Ovation Tax Group. All rights reserved. </p>
                <a id="privacyPolicyButton" className="underline" href="/privacy-policy">Privacy Policy</a>
            </div>
            {/* Footer end */}
        </div>
    );
}
