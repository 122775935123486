import React, { useState } from 'react';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/outline';


export default function AccordionItem(props) {
    const [isActiveTab, setIsActiveTab] = useState(false);

    return (
        <div>
            <div onClick={() => setIsActiveTab(!isActiveTab)}
                className="
                            py-5     
                            bg-white space-y-6 shadow
                            text-gray-500 
                            hover:bg-zinc-100
                            cursor-pointer 
                            md:p-5 
                            p-2
                            my-2
                            rounded-md">
                <div className="flex justify-between">
                    <span className='lato2 font-bold text-xl text-gray-700'>
                        {props.item.question}
                    </span>

                    <div className='text-gray-400 font-black'>
                        {isActiveTab ? <ChevronUpIcon className='h-5 w-5' /> : <ChevronDownIcon className='h-5 w-5' />}
                    </div>
                </div>
            </div>
            {isActiveTab &&
                <div className='bg-white shadow -mt-10'>
                    <div className='md:p-15 p-10 text-gray-700 text-left'>
                        <p>{props.item.answer}</p>
                    </div>
                </div>
            }
        </div>
    );
}
