import React, { useState } from 'react'
import { commaSeparator } from './utils';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline'


export default function Step2({ prevStep, nextStep, updateFieldValue, totalDebt }) {

  const [value, setValue] = useState(totalDebt);

  const handleNextStep = () => {
      updateFieldValue("totalDebt", value);
      nextStep(2)
  }

   

    return (
      <div id="quizFormStep2" className='md:flex items-center justify-center'>
            <div>
                <button
                    id="quizFormStep2PrevButton"
                    onClick={() => prevStep(2)}
                    className="hidden md:block w-fit bg-gray-300 hover:bg-yellow-500 text-black text-md font-bold uppercase tracking-widest p-4 rounded-md">
                    <ChevronLeftIcon className='h-5 w-5' />
                </button>
            </div>
            <div className="bg-white rounded-lg p-10 max-w-sm mx-auto md:mx-2">
            <div>
                    <label htmlFor="owe" className="block text-md font-medium mb-2 text-gray-700">
                    How much do you owe in back taxes? <span className='text-gray-500 text-sm'>($5,000 minimum)</span>
                    </label>
                    

                    <div className='my-10'>
                        <input
                            className='slider border border-gray-200'
                            type="range" min="5000" max="200000" value={value} step="2500"
                            onChange={({ target: { value: radius } }) => {
                                setValue(radius);
                            }}
                        />
                    </div>

                    <div>
                        <div className='font-semibold md:text-4xl text-center rounded-lg text-3xl text-gray-700'>
                            ${commaSeparator(value)}
                        </div>
                    </div>
                    
                </div>
            </div>
            <div>
                <button
                    id="quizFormStep2NextButton"
                    onClick={() => handleNextStep()}
                    className="hidden md:block w-fit bg-gray-300 hover:bg-yellow-500 text-black text-md font-bold uppercase tracking-widest p-4 rounded-md">
                    <ChevronRightIcon className='h-5 w-5' />
                </button>
            </div>
            <div className='md:hidden flex items-center justify-center mt-5'>
                <button
                    id="quizFormStep2PrevButtonMobile"
                    onClick={() => prevStep(2)}
                    className="w-fit bg-yellow-500 text-black mx-2 hover:bg-gray-300 text-md font-bold uppercase tracking-widest p-4 rounded-md">
                    Previous
                </button>
                <button
                    id="quizFormStep2NextButtonMobile"
                    onClick={() => handleNextStep()}
                    className="w-fit bg-yellow-500 hover:bg-gray-300 mx-2 text-black text-md font-bold uppercase tracking-widest p-4 rounded-md">
                    Next
                </button>
            </div>
            <style>
                {`
                .slider {
                    -webkit-appearance: none;
                    width: 100%;
                    height: 45px;
                    border-radius: 5px;  
                    background: white;
                    outline: none;
                    -webkit-transition: .2s;
                    transition: opacity .2s;
                }
                
                .slider::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    appearance: none;
                    width: 55px;
                    height: 55px;
                    border-radius: 50%; 
                    border:none;
                    background: #20a3fd;
                    cursor: pointer;
                }

                .slider::-webkit-slider-thumb:hover{
                    background: #20a3fd;
                }
                
                .slider::-moz-range-thumb {
                    width: 55px;
                    height: 55px;
                    border:none;
                    border-radius: 50%;
                    background: #20a3fd;
                    cursor: pointer;
                }
            `}
            </style>
        </div>
    );
}