import './App.css';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import LandingPage from './components/LandingPage';
import Layout from './components/Layout';
import PrivacyPolicy from './components/PrivacyPolicy';
import Success from './components/Success';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout did={`800-668-1781`}/>}>
              <Route path='/' element={<LandingPage did={`800-668-1781`}/>} />
              <Route path='/privacy-policy' element={<PrivacyPolicy did={`800-668-1781`}/>} />
              <Route path='/confirmation/success' element={<Success did={`800-668-1781`}/>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
export default App;